import React from 'react';

import Main from '../components/Warranty/Main';
import Header from '../components/Warranty/Header';
import Footer from '../components/Footer';
import useCountryLocale from '../hooks/use-country-locale';

export default function FamilyWarranty() {
  useCountryLocale('US', 'en');
  return (
    <>
      <Header />
      <Main />
      <Footer background="#232323" />
    </>
  );
}
